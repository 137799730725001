import { FC } from 'react'
import styled from 'styled-components'
import { Device } from 'twilio-client'
import { Call } from '../types'
import { CallSection } from '../fragments/CallSection'

const CallsBase = styled.section`
  overflow-y: auto;
  padding: 0 1rem;
`

export const ActiveCalls: FC<{
  updateCalls: () => Promise<void>
  calls: Call[] | null
  device: Device | null
}> = ({ updateCalls, calls, device }) => {
  const activeCalls = calls?.filter(
    (call) => call.callStatus === 'ringing' && call.queue !== 'live',
  )

  return (
    <CallsBase>
      <h1>Call Queue</h1>
      {activeCalls == null ? (
        <p>Fetching active calls...</p>
      ) : activeCalls.length === 0 ? (
        <p>No active calls.</p>
      ) : (
        activeCalls.map((call) => (
          <CallSection
            key={call.id}
            updateCalls={updateCalls}
            call={call}
            device={device}
          />
        ))
      )}
    </CallsBase>
  )
}
