import React, { FC } from 'react'
import styled from 'styled-components'
import { Temporal } from 'proposal-temporal'
import { Call } from '../types'

const CallsBase = styled.section`
  overflow-y: auto;
  padding: 0 1rem;
`

export const CallHistory: FC<{
  calls: Call[] | null
}> = ({ calls }) => {
  const completeCalls = calls?.filter((call) => call.callStatus === 'completed')

  return (
    <CallsBase>
      <h1>Call History</h1>
      {completeCalls == null ? (
        <p>Fetching active calls...</p>
      ) : completeCalls.length === 0 ? (
        <p>No completed calls.</p>
      ) : (
        completeCalls
          .sort((a, b) => b.createdAt - a.createdAt)
          .map((call) => <CallSection key={call.id} call={call} />)
      )}
    </CallsBase>
  )
}

const Base = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  background-color: var(--white);
  border-radius: 0.25rem;
  box-shadow: #5f5f5f57 0px 2px 4px;
  padding: 0 1rem;
  margin: 1rem 0;
`
const CallSection: FC<{ call: Call }> = ({ call }) => {
  const time = Temporal.Instant.fromEpochMilliseconds(call.createdAt)
  const durr = Temporal.Duration.from({ seconds: call.callDuration })
  const { hours, minutes, seconds } = durr
  return (
    <Base>
      <dl>
        <dt>Number</dt>
        <dd>
          {call.fromNumber} - {call.fromCountry}
        </dd>
        <dt>Call Duration</dt>
        <dd>
          {hours && `${hours} hours `}
          {minutes && `${minutes} mins `}
          {seconds || '0'} secs
        </dd>
        <dt>Call Date</dt>
        <dd>{time.toLocaleString()}</dd>
      </dl>
      <p>Notes: {call.notes ? <pre>{call.notes}</pre> : <i>no notes.</i>}</p>
    </Base>
  )
}
