import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { QueryCache, ReactQueryCacheProvider } from 'react-query'
import Home from './pages/Home/Home'
import Auth from './pages/Auth/Auth'
import Dashboard from './pages/Dashboard'
import { getSession } from './utils'

const queryCache = new QueryCache()

export default function App() {
  return (
    <ReactQueryCacheProvider queryCache={queryCache}>
      <Router>
        <Switch>
          <Route path="/register">
            <Auth />
          </Route>

          <Route path="/login">
            <Auth />
          </Route>

          <PrivateRoute path="/" />
        </Switch>
      </Router>
    </ReactQueryCacheProvider>
  )
}

/**
 * A component to protect routes.
 * Shows Auth page if the user is not authenticated
 */
const PrivateRoute = ({ component, ...options }) => {
  const session = getSession()

  const finalComponent = session ? Dashboard : Home
  return <Route {...options} component={finalComponent} />
}
