import { FC, ReactEventHandler, useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Device, Connection } from 'twilio-client'
import { Call } from '../types'
import { RelativeTime } from '../fragments/RelativeTime'
import {
  callNotes,
  callReturnToQueue,
  callHangUp,
  callAnswer,
} from '../utils/api'
import { getSession } from '../utils/helpers'

const Base = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  background-color: var(--white);
  border-radius: 0.25rem;
  box-shadow: #5f5f5f57 0px 2px 4px;
  padding: 0 1rem;
  margin: 1rem 0;
  textarea {
    width: 100%;
    min-height: 5rem;
  }
`
const Actions = styled.div`
  grid-column: 1/3;
  padding: 0.5em 0;
  button {
    cursor: pointer;
    min-width: 300px;
    margin-right: 0.25em;
    display: inline-block;
    padding: 0.35em 1.2em;
    border: 0.1em solid black;
    margin: 0 0.3em 0.3em 0;
    border-radius: 0.12em;
    box-sizing: border-box;
    text-decoration: none;
    color: black;
    text-align: center;
    transition: all 0.2s;
    &:hover {
      color: #000000;
      background-color: #ffffff;
    }
  }
`
export const CallSection: FC<{
  updateCalls: () => Promise<void>
  call: Call
  device: Device | null
}> = ({ updateCalls, call, device }) => {
  const session = getSession()
  const [notes, setNotes] = useState<string>(call.notes)
  const pendingNoteSave = useRef<number | null>(null)
  const [status, setStatus] = useState<string | null>(null)
  const [connection, setConnection] = useState<Connection | null>(() => {
    // device?.connections.find(connection => connection.);
    // TODO: find this calls id in connections
    debugger
    return null
  })

  useEffect(() => {
    // consume externally updated notes
    if (call.notes !== notes && !pendingNoteSave.current) {
      setNotes(call.notes)
    }
  }, [call.notes, notes])

  const handleChangeNotes: ReactEventHandler<HTMLTextAreaElement> = (e) => {
    const { value: notes } = e.currentTarget
    setNotes(notes)
    if (pendingNoteSave.current) {
      clearTimeout(pendingNoteSave.current)
    }
    pendingNoteSave.current = setTimeout(async () => {
      await callNotes(session?.userToken, call.twilioId, notes)
      pendingNoteSave.current = null
    }, 2000)
  }

  async function handleAnswer() {
    if (!device) {
      alert('please setup your audio')
      return
    }
    setStatus('answering')

    const connection = await new Promise<Connection>((resolve, reject) => {
      const handleConnection = (conn: Connection) => {
        if (conn.parameters.From !== call.fromNumber) {
          conn.reject()
          return
        }
        conn.accept()
        resolve(conn)
        device.off('incoming', handleConnection)
      }
      device.on('incoming', handleConnection)
      callAnswer(session?.userToken, call.twilioId)
    })
    setConnection(connection)
  }

  useEffect(() => {
    if (!connection) return
    connection.on('disconnect', handleDisconnect)
    function handleDisconnect() {
      setConnection(null)
    }
    connection.on('ringing', handleRinging)
    function handleRinging() {
      setStatus('ringing')
    }
    return () => {
      connection.off('disconnect', handleDisconnect)
      connection.off('ringing', handleRinging)
    }
  }, [connection])

  return (
    <Base>
      <div>
        <dl>
          <dt>Number</dt>
          <dd>
            {call.fromNumber} - {call.fromCountry}
          </dd>
          <dt>Call Time</dt>
          <dd>
            <RelativeTime time={call.createdAt} />
          </dd>
        </dl>
      </div>
      <div>
        <p>Notes:</p>
        <textarea value={notes} onChange={handleChangeNotes}></textarea>
      </div>
      <Actions>
        {call.callStatus === 'ringing' && !connection && (
          <button disabled={status === 'answering'} onClick={handleAnswer}>
            {status === 'answering' ? 'Answering...' : 'Talk here'}
          </button>
        )}
        {(connection || call.queue !== 'standard') && (
          <button
            disabled={status === 'returning-to-queue'}
            onClick={async () => {
              setStatus('returning-to-queue')
              await callReturnToQueue(session?.userToken, call.id)
              await updateCalls()
              setStatus('ready')
            }}>
            {status === 'returning-to-queue'
              ? 'transferring...'
              : 'Transfer to queue'}
          </button>
        )}
        {(connection || call.queue !== 'live') && (
          <button
            disabled={status === 'returning-to-queue-live'}
            onClick={async () => {
              setStatus('returning-to-queue-live')
              await callReturnToQueue(session?.userToken, call.id, 'live')
              await updateCalls()
              setStatus('ready')
            }}>
            {status === 'returning-to-queue-live'
              ? 'transferring...'
              : 'Transfer to live queue'}
          </button>
        )}
        <button onClick={() => callHangUp(session?.userToken, call.twilioId)}>
          Hang up
        </button>
      </Actions>
    </Base>
  )
}
