import Cookies from 'js-cookie'

/**
 * Format Org and Username correctly for the Serverless Platform backend
 */
export const formatOrgAndUsername = (name = '') => {
  name = name
    .toString()
    .toLowerCase()
    .replace(/[^a-z\d-]+/gi, '-')
  // Remove multiple instances of hyphens
  name = name.replace(/-{2,}/g, '-')
  if (name.length > 40) {
    name = name.substring(0, 40)
  }
  return name
}

export const parseQueryParams = (searchString?: string) => {
  if (!searchString) {
    return null
  }

  // Clone string
  let clonedParams = (' ' + searchString).slice(1)

  return clonedParams
    .substr(1)
    .split('&')
    .filter((el) => el.length)
    .map((el) => el.split('='))
    .reduce((accumulator, currentValue) => {
      accumulator[decodeURIComponent(currentValue[0])] = decodeURIComponent(
        currentValue[1],
      )
      return accumulator
    }, {} as Record<string, string>)
}

/**
 * Parse hash fragment parameters in a URL
 */
export const parseHashFragment = (hashString: string) => {
  let hash = decodeURI(hashString).split('&')
  return hash.reduce((hashData, item) => {
    const [key, val] = item.replace('#', '').split('=')
    hashData[key] = val
    return hashData
  }, {} as Record<string, string>)
}

export interface UserSession {
  userEmail: string
  userId: string
  userToken: string
  twilioToken?: string
}

export const saveSession = (
  userId: string,
  userEmail: string,
  userToken: string,
  twilioToken?: string,
) => {
  const session = { userId, userEmail, userToken, twilioToken }
  Cookies.set('serverless', session)
  return session
}

export const deleteSession = () => {
  Cookies.remove('serverless')
}

export const getSession = (): UserSession | null => {
  const data = Cookies.get('serverless')
  if (!data) {
    return null
  }
  const session = tryParse(data)
  if (!isUserSession(session)) {
    return null
  }
  return session
}

const isUserSession = (obj: any): obj is UserSession =>
  typeof obj.userId == 'string' &&
  typeof obj.userEmail == 'string' &&
  typeof obj.userToken == 'string'

export const tryParse = (data: string): object | null => {
  try {
    return JSON.parse(data)
  } catch (e) {
    return null
  }
}
