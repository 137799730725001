import { useState, useEffect, useRef, useCallback } from 'react'
import { Link, Redirect, Route, Switch, useHistory } from 'react-router-dom'
import { Connection, Device } from 'twilio-client'
import { callsGet, userRefresh } from '../utils/api'
import {
  getSession,
  saveSession,
  deleteSession,
  UserSession,
  tryParse,
} from '../utils'
import { ActiveCalls } from './ActiveCalls'
import { LiveQueue } from './LiveQueue'
import { CallHistory } from './CallHistory'
import { Call } from '../types'
import styled from 'styled-components'

const Main = styled.main`
  display: grid;
  grid-template:
    'nav header' 4rem
    'nav content' 1fr
    'nav footer' 40px
    / 220px 1fr;
  min-height: 100vh;
  header {
    grid-area: header;
  }
  nav {
    grid-area: nav;
  }
  .content {
    grid-area: content;
  }
`
const Header = styled.header`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem;
  color: #777;
  border-bottom: 1px solid var(--background);
  button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0 0.5rem;
    &:hover {
      color: var(--primary);
    }
  }
`
const Nav = styled.nav`
  background-color: var(--background);
  padding: 0em 1rem;
  h2 {
    text-align: center;
    border-bottom: 1px solid var(--main-background);
    height: calc(2rem - 1px);
    padding: 1rem 0;
    margin: 0;
  }
  h2,
  a {
    color: var(--white);
  }
  a:hover {
    color: var(--primary);
  }
  ul {
    padding: 0;
    list-style: none;
  }
  li {
    padding: 0 0.5em;
  }
`

const useSession = () => {
  const session = getSession()
  const [currSession, setSession] = useState<UserSession | null>(session)
  useEffect(() => {
    if (!currSession || !currSession.twilioToken) {
      return
    }
    const { userToken, twilioToken } = currSession
    try {
      const data = tryParse(atob(twilioToken.split('.')[1])) as {
        exp?: number
      } | null
      if (!data || 'exp' in data || typeof data.exp !== 'number') {
        return
      }
      const timeout = setTimeout(async () => {
        const token = await userRefresh(userToken)
        const session = saveSession(
          currSession.userId,
          currSession.userEmail,
          token.token,
          token.twilioToken,
        )
        setSession(session)
      }, data.exp * 1000)
      return () => clearTimeout(timeout)
    } catch (e) {}
  }, [currSession])
  return currSession
}

function Dashboard() {
  const history = useHistory()
  const [unauthorized, setUnauthorized] = useState(false)
  const session = useSession()
  const [calls, setCalls] = useState<Call[] | null>(null)
  const [device, setDevice] = useState<Device | null>(null)

  useEffect(() => {
    if (!session || !session.twilioToken) {
      return
    }
    let { twilioToken } = session
    setDevice(new Device(twilioToken))
  }, [session])

  useEffect(() => {
    if (!session?.twilioToken || !device) {
      return
    }
    device.updateToken(session.twilioToken)
  }, [session?.twilioToken, device])

  const timeout = useRef<number>()
  const getCalls = useCallback(
    async function getCalls() {
      if (!session) return
      try {
        setCalls(await callsGet(session.userToken))
        timeout.current = setTimeout(getCalls, 5000)
      } catch (err) {
        if (err.message === 'Unauthorized') {
          setUnauthorized(true)
          return
        }
        throw err
      }
    },
    [session],
  )
  useEffect(() => {
    ;(async () => {
      if (!session) {
        return
      }
      await getCalls()
    })()
    return () => {
      timeout.current && clearTimeout(timeout.current)
    }
  }, [session, getCalls])

  function logout() {
    deleteSession()
    history.push(`/`)
  }

  return (
    <>
      <Redirect exact from="/" to="/call-queue" />
      <Main>
        <Header>
          <span>
            {session && <span>{session.userEmail}</span>}{' '}
            <button onClick={logout}>Log Out</button>
          </span>
        </Header>
        <Nav>
          <h2>Broad Radio</h2>
          <ul>
            <li>
              <Link to="/call-queue">Call Queue</Link>
            </li>
            <li>
              <Link to="/live-queue">Live Queue</Link>
            </li>
            <li>
              <Link to="/call-history">Call History</Link>
            </li>
          </ul>
        </Nav>
        <div className="content">
          {unauthorized ? (
            <>
              <h1>Unauthorised</h1>
              <p>Please contact an admin to manage calls.</p>
            </>
          ) : (
            <Switch>
              <Route
                path="/call-queue"
                render={() => (
                  <ActiveCalls
                    updateCalls={getCalls}
                    calls={calls}
                    device={device}
                  />
                )}
              />
              <Route
                path="/live-queue"
                render={() => (
                  <LiveQueue
                    updateCalls={getCalls}
                    calls={calls}
                    device={device}
                  />
                )}
              />
              <Route
                path="/call-history"
                render={() => <CallHistory calls={calls} />}
              />
            </Switch>
          )}
        </div>
      </Main>
    </>
  )
}

export default Dashboard
