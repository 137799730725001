import { useState, useEffect, FC } from 'react'
import { Temporal } from 'proposal-temporal'

export const RelativeTime: FC<{ time: number }> = ({ time }) => {
  const [, setTick] = useState(0)
  useEffect(() => {
    const interval = setInterval(() => {
      setTick((t) => t + 1)
    }, 1000)
    return () => clearInterval(interval)
  })
  const durr = Temporal.Instant.fromEpochMilliseconds(time).until(
    Temporal.now.instant(),
    {
      largestUnit: 'hours',
      smallestUnit: 'seconds',
    },
  )
  const { hours, minutes, seconds } = durr

  return (
    <span>
      {hours && `${hours} hours `}
      {minutes && `${minutes} mins `}
      {seconds || '0'} secs
    </span>
  )
}
