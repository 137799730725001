/**
 * Utils: Back-end
 */

import config from '../config'

/**
 * Register a new user
 */
export const userRegister = async (email, password) => {
  return await requestApi('/users/register', 'POST', { email, password })
}

/**
 * Login a new user
 */
export const userLogin = async (email, password) => {
  return await requestApi('/users/login', 'POST', { email, password })
}

/**
 * Refresh session
 */
export const userRefresh = async (token) => {
  return await requestApi('/users/refresh', 'POST', null, {
    Authorization: `Bearer ${token}`,
  })
}

/**
 * userGet
 */
export const userGet = async (token) => {
  return await requestApi('/users/me', 'GET', null, {
    Authorization: `Bearer ${token}`,
  })
}

/**
 * Login a new user
 */
export const callsGet = async (token) => {
  return await requestApi('/calls', 'GET', null, {
    Authorization: `Bearer ${token}`,
  })
}

/**
 * Login a new user
 */
export const callNotes = async (token, id, notes) => {
  return await requestApi(
    `/calls/${id}/notes`,
    'POST',
    { notes },
    {
      Authorization: `Bearer ${token}`,
    },
  )
}

/**
 * Disconnect from a call, optionally hanging up
 */
export const callAnswer = async (token, id) => {
  return await requestApi(
    `/calls/${id}/answer`,
    'POST',
    {},
    {
      Authorization: `Bearer ${token}`,
    },
  )
}

/**
 * Disconnect from a call, optionally hanging up
 */
export const callHangUp = async (token, id) => {
  return await requestApi(
    `/calls/${id}/hang-up`,
    'POST',
    {},
    {
      Authorization: `Bearer ${token}`,
    },
  )
}

/**
 * Disconnect from a call, optionally hanging up
 */
export const callReturnToQueue = async (token, id, queue = 'standard') => {
  return await requestApi(
    `/calls/${id}/return-to-queue`,
    'POST',
    { queue },
    {
      Authorization: `Bearer ${token}`,
    },
  )
}

/**
 * API request to call the backend
 */
export const requestApi = async (
  path = '',
  method = 'GET',
  data = null,
  headers = {},
) => {
  // Check if API URL has been set
  if (!config?.domains?.api) {
    throw new Error(
      `Error: Missing API Domain – Please add the API domain from your serverless Express.js back-end to this front-end application.  You can do this in the "site" folder, in the "./config.js" file.  Instructions are listed there and in the documentation.`,
    )
  }

  // Prepare URL
  if (!path.startsWith('/')) {
    path = `/${path}`
  }
  const url = `${config.domains.api}${path}`

  // Set headers
  headers = Object.assign({ 'Content-Type': 'application/json' }, headers)

  // Default options are marked with *
  const response = await fetch(url, {
    method: method.toUpperCase(),
    mode: 'cors',
    cache: 'no-cache',
    headers,
    body: data ? JSON.stringify(data) : null,
  })

  if (response.status < 200 || response.status >= 300) {
    const error = await response.json()
    throw new Error(error.error)
  }

  return await response.json()
}
