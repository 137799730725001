import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import styles from './Home.module.css'

class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <div className={`${styles.container} animateFadeIn`}>
        <div className={styles.containerInner}>
          <div className={`${styles.heroDescription}`}>Broad Radio</div>

          <div className={`${styles.containerCta}`}>
            <Link to="/register">
              <button className={`buttonPrimaryLarge`}>Register</button>
            </Link>

            <Link to="/login" className={`${styles.linkSignIn}`}>
              sign-in
            </Link>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(Home)
